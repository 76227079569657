<template>
    <modal @hide="hide" :show="show" size="md" :loading="loading">
        <h2 slot="header">Calculate emissions</h2>

        <div slot="body" class="pb-6">
            <div v-if="!uploadInProgress">
                <p>Calculate emissions for external shipments by uploading a correct formatted .xlsx file. If you need a template file, please click the button below.</p>
            </div>
            <div class="mt-6" v-if="!uploadInProgress">
                <a href="/templates/emissions-template.xlsx" class="btn-transparent">Download template</a>
            </div>
            <div class="flex flex-wrap mt-6" v-if="!uploadInProgress">
                <div class="w-full">
                    <div class="relative flex flex-wrap w-full pr-8 file-input">
                        <modal-field label="Excel file" class="flex">
                            <label 
                                class="inline-block btn btn-sm cursor-pointer btn-primary" 
                                for="file"
                            >
                                Select Excel file
                            </label>
                            <input 
                                class="hidden"
                                id="file"
                                type="file"
                                name="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                @change="handleFile"
                            />
                        </modal-field>
                        <span 
                            class="flex items-end w-1/2 px-2 ml-2 text-gray-500 border-b w-80 file-info"
                            :class="{'border-b border-red-500' : validationErrors.excelFile}"
                        >
                            <span v-if="!fileInput">No file has been uploaded yet</span>
                            <span class="text-gray-800" v-else>{{ fileInput }}</span>
                        </span>
                        <transition name="fade">
                            <span
                                v-if="fileNotCorrect"
                                class="absolute bottom-0 w-full -mb-6 text-red-500 help-block"
                            >
                                <span v-if="fileNotCorrect">File was not in correct format</span>
                            </span>
                        </transition>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap mt-6" v-if="!uploadInProgress">
                <div class="w-full">
                    <button class="btn float-right" @click="uploadAndCalculate" :disabled="uploadInProgress || !file">
                        Calculate
                    </button>
                </div>
            </div>
            <div v-if="uploadInProgress">
                <p>Your file is being calculated. A new file with the results will be downloaded in a short while.</p>
            </div>
            <div class="flex flex-wrap mt-6" v-if="uploadInProgress">
                <div class="w-full">
                    <button class="btn float-right" @click="hide">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<style lang="scss">
    @keyframes spinner {
        to { transform: rotate(360deg); }
    }
    .fa-spinner {
        animation: spinner 1s linear infinite;
    }
</style>

<script>
    import axios from 'axios';
    import moment from "moment";

    export default {
        name: 'emissionsCalculateUploadedExcelDialog',

        props: {
            showing: Boolean
        },

        watch: {
            showing:{
                handler(){
                    this.show = this.showing;

                    if(this.show === true){
                        this.fileInput = null;
                        this.file = null;
                    }
                }
            }
        },

        // metaInfo () {
        //     return { title: 'Emissions calculate from Excel' }
        // },

        data: () => ({
            loading: false,
            show: false,
            validationErrors: {},
            fileInput: null,
            file: null,
            fileNotCorrect: false,
            spinner: null,
            uploadInProgress: false
        }),

        computed: {
        },

        created() {
            this.fileInput = null;
            this.file = null;
        },

        methods: {
            hide() {
                this.show = false;
                this.$emit('hide');
            },

            handleFile(file) {
                this.fileInput = file.target.files[0].name;
                this.file = file.target.files[0];
            },

            uploadAndCalculate(){
                this.uploadInProgress = true;
                let formData = new FormData();
                formData.append('file', this.file);

                this.spinner = this.$snotify.html(`<div class="flex flex-wrap" style="width: 100%;"><div class="w-3/4">Calculating file..</div><div class="w-1/4" style="position: relative"><svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="spinner-third" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-spinner svg-inline--fa fa-spinner-third fa-w-16 float-right" style="transform-origin: 0.5em 0.5em; position: absolute; top: 3px; left: 65px;"><g transform="translate(256 256)" class=""><g transform="translate(0, 0)  scale(1.375, 1.375)  rotate(0 0 0)" class=""><g transform="translate(-256 -256)" class="fa-group"><path fill="currentColor" d="M478.71 364.58zm-22 6.11l-27.83-15.9a15.92 15.92 0 0 1-6.94-19.2A184 184 0 1 1 256 72c5.89 0 11.71.29 17.46.83-.74-.07-1.48-.15-2.23-.21-8.49-.69-15.23-7.31-15.23-15.83v-32a16 16 0 0 1 15.34-16C266.24 8.46 261.18 8 256 8 119 8 8 119 8 256s111 248 248 248c98 0 182.42-56.95 222.71-139.42-4.13 7.86-14.23 10.55-22 6.11z" class="fa-secondary"></path><path fill="currentColor" d="M271.23 72.62c-8.49-.69-15.23-7.31-15.23-15.83V24.73c0-9.11 7.67-16.78 16.77-16.17C401.92 17.18 504 124.67 504 256a246 246 0 0 1-25 108.24c-4 8.17-14.37 11-22.26 6.45l-27.84-15.9c-7.41-4.23-9.83-13.35-6.2-21.07A182.53 182.53 0 0 0 440 256c0-96.49-74.27-175.63-168.77-183.38z" class="fa-primary"></path></g></g></g></svg></div></div>`,
                {
                    timeout: 0
                });

                const spinnerId = this.spinner.id;
                const snotify = this.$snotify;
                axios.post(this.$apiUrl.emissions.upload , formData, {
                    responseType: "blob"
                }).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "calculated-emissions-" + moment().format("YYYY-MM-DD-HH-mm-ss") + ".xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    this.uploadInProgress = false;

                    snotify.remove(spinnerId);

                    snotify.success('Your file has been calculated!');

                    if(this.file){
                        this.file = null;
                        this.fileInput = null;
                    }
                }).catch(err => {
                    this.uploadInProgress = false;
                    this.fileNotCorrect = true;
                    snotify.remove(spinnerId);

                    snotify.error('An error occurred while calculating your file.');
                });
            }
        }
    }
</script>
